import React from "react";
import Modal from "antd/lib/modal/Modal";

const ModalDelete = ({ visible, handleOk, handleCancel }) => {
  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      title="Вы действительно хотите удалить торг?"
      cancelText="Отмена"
      okText="Удалить"
      bodyStyle={{ display: "none" }}
    ></Modal>
  );
};

export default ModalDelete;
