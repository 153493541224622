import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RouteWrapper from "./navigation/RouteWrapper/RouteWrapper";
import Auction from "./routes/Auction";
import Auctions from "./routes/Auctions";
import Auth from "./routes/Auth";
import NotFound from "./routes/NotFound/NotFound";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <RouteWrapper>
            <Auth />
          </RouteWrapper>
        </Route>
        <Route exact path="/auctions">
          <RouteWrapper>
            <Auctions />
          </RouteWrapper>
        </Route>
        <Route exact path="/auctions/:id">
          <RouteWrapper>
            <Auctions />
          </RouteWrapper>
        </Route>
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
