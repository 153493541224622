export function commonCreateMethod(method, apiFieldName, value, success) {
  method({ [apiFieldName]: value }, success);
}

export function commonDeleteMethod(method, id, new_id, success) {
  method(id, new_id, success);
}
export function commonUpdateMethod(method, id, apiFieldName, value, success) {
  method(id, { [apiFieldName]: value }, success);
}
