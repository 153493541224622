import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Input,
  Form,
  Row,
  Space,
  Col,
  Select,
  AutoComplete,
  TimePicker,
} from "antd";
import FormItemWithCRUD from "../components/FormItemWithCRUD/FormItemWithCRUD";
import {
  Auction,
  AuctionForm,
  Employee,
  Marketplace,
  OurOrganization,
} from "../api/methods";
import CustomDatePicker from "../components/CustomDatePicker/CustomDatePicker";
import moment from "moment";
const { Option } = Select;

const layout = {
  labelCol: {
    // span: 10,
  },
  wrapperCol: {
    // span: 10,
  },
};

const initialAuctionInfo = {
  id: "",
  name: "",
  city: "",
  form_id: "",
  description: "",
  description2: "",
  supply_date: "",
  agree_date: "",
  execution_date: "",
  period_type: 1,
  period_date: "",
  max_price: 0,
  security_amount: "",
  fixed_price: 0,
  our_org_id: "",
  href: "",
  designer_id: "",
  executer_id: "",
  marketplace_id: "",
  status: 2,
};
const AuctionMainInfo = ({
  visible,
  auctionId,
  onClose,
  isCreating = false,
}) => {
  const [auctionInfo, setAuctionInfo] = useState(initialAuctionInfo);

  const [options, setOptions] = useState([]);

  const [period, setPeriod] = useState({
    from: "",
    to: "",
  });

  const [ex_date, setExDate] = useState({ date: "", time: "" });

  const handleChangePeriod = (e) => {
    const { name, value } = e.target;
    setPeriod({ ...period, [name]: value });
  };

  const getAuctionById = (id) => {
    const success = ({ data }) => {
      setAuctionInfo(data);
      const [date, time] = data.execution_date.split(" ");
      console.log("asdas ", date, time);
      setExDate({ date, time });
      setPeriod(JSON.parse(data.period_date));
    };
    Auction.getById(id, success);
  };

  useEffect(() => {
    if (isCreating) setAuctionInfo(initialAuctionInfo);
  }, [isCreating]);

  useEffect(() => {
    getAuctionById(auctionId);
  }, [auctionId, visible]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isPeriodType = name == "period_type";
    const isPeriodDate = name == "period_date";
    const period_date = isPeriodDate
      ? value
      : isPeriodType
      ? ""
      : auctionInfo.period_date;

    setAuctionInfo({ ...auctionInfo, [name]: value, period_date });
  };

  const createUpdateSuccess = () => {
    onClose();
    setAuctionInfo(initialAuctionInfo);
  };

  const createAuction = () => {
    const { period_type, period_date } = auctionInfo;
    const auctionInfoWithPeriod = {
      ...auctionInfo,
      period_date: period_type == 4 ? JSON.stringify(period) : period_date,
      execution_date: ex_date.date + " " + ex_date.time,
    };
    Auction.create(auctionInfoWithPeriod, createUpdateSuccess);
  };

  const updateAuction = () => {
    const { period_type, period_date } = auctionInfo;
    const auctionInfoWithPeriod = {
      ...auctionInfo,
      period_date: period_type == 4 ? JSON.stringify(period) : period_date,
      execution_date: ex_date.date + " " + ex_date.time,
    };
    Auction.update(auctionId, auctionInfoWithPeriod, createUpdateSuccess);
  };

  const onFinish = () => (isCreating ? createAuction() : updateAuction());

  const onFinishFailed = (err) => {
    console.log(err);
  };

  const handlerChangeName = (value) => {
    const success = ({ data }) => {
      data = data.map((d) => {
        return { value: d };
      });
      setOptions(data);
    };

    setAuctionInfo({ ...auctionInfo, name: value });
    Auction.getNamesBySubstring({ organization: value }, success);
  };

  const handleChangeExecutionDateTime = (e) => {
    const { name, value } = e.target;
    setExDate({ ...ex_date, [name]: value });
    // name == "date" ? (date = value + " " + time) : (time = date + " " + value);
    // if (name == "time" && value != "" && value != null && value) {
    //   console.log(name, time, time.hour(), time.minutes());
    //   time = `${time.hour()}:${time.minutes()}:00`;
    //   console.log(time);
    // }
    // setExDate({ date, time });
  };

  return (
    <div>
      <Form
        {...layout}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Space size="small">
          {isCreating ? null : (
            <Form.Item label="Номер">
              <Input
                name="id"
                disabled
                onChange={handleChange}
                value={auctionInfo.id}
              />
            </Form.Item>
          )}

          <Form.Item
            label="Заказчик"
            required
            validateStatus={auctionInfo.name == "" ? "error" : "validating"}
          >
            <AutoComplete
              style={{ width: 203 }}
              options={options}
              onChange={handlerChangeName}
              value={auctionInfo.name}
            />
            {/* <FormItemWithCRUD
            name="name"
            defaultValue={auctionInfo.name}
            onChange={handleChange}
            apiFieldName={"our_org"}
            methods={OurOrganization}
          ></FormItemWithCRUD> */}
          </Form.Item>

          <Form.Item label="Населеннй пункт">
            <Input
              name="city"
              onChange={handleChange}
              value={auctionInfo.city}
            />
          </Form.Item>
        </Space>
        <Form.Item label="Форма торгов" required>
          <FormItemWithCRUD
            required
            name="form_id"
            defaultValue={auctionInfo.form_id}
            onChange={handleChange}
            apiFieldName={"form"}
            methods={AuctionForm}
          ></FormItemWithCRUD>
        </Form.Item>
        <Form.Item label="Описание работ">
          <Input
            name="description"
            onChange={handleChange}
            value={auctionInfo.description}
          />
        </Form.Item>
        <Space>
          <Form.Item label="Дата подачи заявки">
            <CustomDatePicker
              onChange={handleChange}
              name="supply_date"
              value={auctionInfo.supply_date}
            />
          </Form.Item>
          <Form.Item label="Дата проведения торга">
            <CustomDatePicker
              onChange={handleChangeExecutionDateTime}
              name="date"
              value={ex_date.date}
            />

            <TimePicker
              onChange={(v, value) =>
                handleChangeExecutionDateTime({
                  target: { value, name: "time" },
                })
              }
              name="time"
              allowClear={false}
              showSecond={false}
              format="HH:mm"
              value={
                ex_date.time === "" ||
                ex_date.time === "0000-00-00" ||
                ex_date.time == null ||
                typeof ex_date.time === "undefined"
                  ? ""
                  : moment(ex_date.time, "HH:mm:ss")
              }
            ></TimePicker>
          </Form.Item>
          <Form.Item label="Дата одобрения">
            <CustomDatePicker
              onChange={handleChange}
              name="agree_date"
              value={auctionInfo.agree_date}
            />
          </Form.Item>
        </Space>
        <Space>
          <Form.Item label="Максимальная цена">
            <Input
              addonAfter={"₽"}
              name="max_price"
              onChange={handleChange}
              value={auctionInfo.max_price}
            />
          </Form.Item>
          <Form.Item label="Размер обеспечения заявки">
            <Input
              name="security_amount"
              onChange={handleChange}
              value={auctionInfo.security_amount}
            />
          </Form.Item>
          <Form.Item label="Назначенная цена">
            <Input
              name="fixed_price"
              addonAfter={"₽"}
              onChange={handleChange}
              value={auctionInfo.fixed_price}
            />
          </Form.Item>
        </Space>
        <Form.Item label="Организация выполняющая работы">
          <FormItemWithCRUD
            name="our_org_id"
            defaultValue={auctionInfo.our_org_id}
            onChange={handleChange}
            apiFieldName={"our_org"}
            methods={OurOrganization}
          ></FormItemWithCRUD>
        </Form.Item>
        <Space>
          <Form.Item label="Тип периода">
            <Select
              onChange={(value) => {
                handleChange({ target: { name: "period_type", value } });
              }}
              value={auctionInfo.period_type}
            >
              <Select.Option value={1}>Календарные дни</Select.Option>
              <Select.Option value={2}>Рабочие дни</Select.Option>
              <Select.Option value={3}>Дата</Select.Option>
              <Select.Option value={4}>Период</Select.Option>
            </Select>
            {/* <Input
              type="number"
              addonAfter="календарных дней"
              onChange={handleChange}
              name="period_type"
              value={auctionInfo.period_type}
            /> */}
          </Form.Item>
          <Form.Item label="Сроки выполнения работ">
            {auctionInfo.period_type == 1 || auctionInfo.period_type == 2 ? (
              <Input
                onChange={handleChange}
                name="period_date"
                value={auctionInfo.period_date}
              />
            ) : auctionInfo.period_type == 3 ? (
              <CustomDatePicker
                value={auctionInfo.period_date}
                name="period_date"
                onChange={handleChange}
              />
            ) : (
              <>
                <CustomDatePicker
                  value={period.from}
                  name="from"
                  onChange={handleChangePeriod}
                />
                {" – "}
                <CustomDatePicker
                  value={period.to}
                  name="to"
                  onChange={handleChangePeriod}
                />
              </>
            )}
          </Form.Item>
        </Space>
        <Form.Item label="Ссылка на аукцион">
          <Input name="href" onChange={handleChange} value={auctionInfo.href} />
        </Form.Item>
        <Form.Item label="Оформитель">
          <FormItemWithCRUD
            name="designer_id"
            defaultValue={auctionInfo.designer_id}
            onChange={handleChange}
            apiFieldName={"employee"}
            methods={Employee}
          ></FormItemWithCRUD>
        </Form.Item>

        <Form.Item label="Исполнитель">
          <FormItemWithCRUD
            name="executer_id"
            defaultValue={auctionInfo.executer_id}
            onChange={handleChange}
            apiFieldName={"employee"}
            methods={Employee}
          ></FormItemWithCRUD>
        </Form.Item>

        <Form.Item label="Торговая площадка">
          <FormItemWithCRUD
            name="marketplace_id"
            defaultValue={auctionInfo.marketplace_id}
            onChange={handleChange}
            apiFieldName={"marketplace"}
            methods={Marketplace}
          ></FormItemWithCRUD>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {isCreating ? "Создать" : "Сохранить"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AuctionMainInfo;
