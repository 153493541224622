import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button, Select, Space, Table } from "antd";

import Navbar from "../navigation/Navbar/Navbar";
import AuctionsFilters from "../components/AuctionsFilters/AuctionsFilters";
import ModalAuctionResult from "../layouts/Modals/ModalAuctionResult";
import ModalCostPrice from "../layouts/Modals/ModalCostPrice";
import ModalDelete from "../layouts/Modals/ModalDelete";
import ModalSetPrice from "../layouts/Modals/ModalSetPrice";
import AuctionInfoDrawer from "../layouts/AuctionInfoDrawer/AuctionInfoDrawer";

import AlertWithTimer from "../components/AlertWithTimer/AlertWithTimer";

import { Auction } from "../api/methods";
import { formatDate } from "../functions/common";

import { STATUSES, BODY } from "../api/constants";
import Text from "antd/lib/typography/Text";

const defaultModalsState = {
  visibleAuctionResult: false,
  visibleCostPrice: false,
  visibleAuctionDelete: false,
  visibleSetPrice: false,
};

const inititalFilters = BODY.filterAuctions;

const Auctions = () => {
  const history = useHistory();
  const [auctionId, setAuctionId] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [filters, setFilters] = useState({ ...inititalFilters, status: 7 });

  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [alertsStates, setAlertsState] = useState({
    deleted: false,
    cost_price: false,
    results: false,
    status_changed: false,
    fixed_price: false,
  });

  const [pagesCount, setPagesCount] = useState(1);

  const showAlertByName = (name) => {
    setAlertsState({ ...alertsStates, [name]: true });
  };

  const loadAllAuctions = () => {
    const success = ({ data }) => {
      const auctions = data.data.map(
        ({
          id,
          status,
          description,
          execution_date,
          href,
          name,
          fixed_price,
          cost_price,
          winning_organization,
          result_price,
          max_price,
        }) => {
          return {
            id,
            key: id,
            organization: name,
            winning_organization,
            workDescription: description,
            winning_organization,
            price: fixed_price,
            costPrice: cost_price,
            result_price,
            max_price,
            dateOfAuction: formatDate(execution_date),
            href,
            status,
          };
        }
      );
      setPagesCount(data.pagesCount);
      setDataSource(auctions.reverse());
    };

    // Auction.getAll(inititalFilters, success);
    Auction.getAll({ ...filters }, success);
  };

  useEffect(() => {
    loadAllAuctions();
  }, [filters]);

  const [stateModals, setStateModals] = useState(defaultModalsState);

  const closeModals = () => {
    setAuctionId(null);
    setStateModals(defaultModalsState);
    loadAllAuctions();
  };

  const handleShowModal = (name, id) => {
    setAuctionId(id);
    setStateModals({ ...stateModals, [name]: true });
  };
  const showDrawer = (id = null) => {
    setIsVisibleDrawer(true);
    setAuctionId(id);
  };

  const handleChangeStatus = (id, status) => {
    const success = () => {
      if (status == 1) {
        const agree_date = new Date().toJSON().split("T")[0];
        Auction.update(id, { agree_date }, loadAllAuctions);
      } else {
        loadAllAuctions();
      }
    };
    Auction.changeStatus(id, { status }, success);
  };

  const onChangeFilters = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const clearFilters = () => {
    setFilters(inititalFilters);
  };

  const deleteAuction = () => {
    const success = () => {
      closeModals();
      loadAllAuctions();
      showAlertByName("deleted");
    };
    Auction.delete(auctionId, success);
  };

  const showDrawerToCreate = () => {
    setIsCreating(true);
    showDrawer();
  };
  const closeDrawer = () => {
    // history.push("/auctions");
    loadAllAuctions();
    setIsCreating(false);
    setIsVisibleDrawer(false);
  };

  const onPageChange = ({ current, pageSize }) => {
    setFilters({ ...filters, page: current });
    // console.log(current);
  };

  const handleChangeStatusFilters = ({ key }) => {
    setFilters({ ...filters, status: key });
  };

  const openNewTab = (href) => window.open(href);

  const columns = [
    {
      title: "№",
      width: 70,
      sorter: (a, b) => a.id - b.id,
      dataIndex: "id",
      fixed: "left",
    },

    {
      title: "Название торга",
      width: 400,
      sorter: (a, b) => a.organization.localeCompare(b.organization),
      dataIndex: "organization",
      fixed: "left",
      render: (text, { href }) =>
        href != "" && href ? (
          <a
            style={{ paddingLeft: 0 }}
            type="link"
            onClick={() => openNewTab(href)}
          >
            {text}
          </a>
        ) : (
          text
        ),
    },
    {
      title: "Описание работ",
      sorter: (a, b) => {
        let wda = a.workDescription;
        let wdb = b.workDescription;
        if (!wda) wda = "";
        if (!wdb) wdb = "";
        return wda.localeCompare(wdb);
      },

      dataIndex: "workDescription",
      width: 400,
      render: (text) => <div>{text}</div>,
    },

    {
      title: "Назн. цена",
      width: 175,
      sorter: (a, b) => a.price - b.price,
      dataIndex: "price",
      align: "right",
      render: (text) => <div>{text} ₽</div>,
    },
    {
      title: "Себестоимость",
      width: 175,
      sorter: (a, b) => a.costPrice - b.costPrice,
      dataIndex: "costPrice",
      align: "right",
      render: (text) => <div>{text} ₽</div>,
    },
    {
      title: "Дата аукциона",
      width: 175,
      sorter: (a, b) => a.dateOfAuction < b.dateOfAuction,
      dataIndex: "dateOfAuction",
      align: "center",
      render: (text) => <div>{text == "Invalid Date" ? "–" : text}</div>,
    },
    {
      title: "Действия",
      key: "operation",
      // fixed: "right",
      width: 300,
      render: (text, { id, status }) => (
        <Space size="small" direction="vertical">
          <Space size="small">
            <Button onClick={() => showDrawer(id)} size="small" type="primary">
              Подробнее
            </Button>
            <Button size="small" type="ghost">
              Отчет
            </Button>
            <Button
              size="small"
              type="ghost"
              onClick={() => handleShowModal("visibleCostPrice", id)}
            >
              Себестоимость
            </Button>
            <Button
              size="small"
              type="ghost"
              onClick={() => handleShowModal("visibleAuctionResult", id)}
            >
              Результаты торгов
            </Button>
          </Space>
          <Space size="small">
            <Select
              size="small"
              style={{ width: "fit-content" }}
              defaultValue={status}
              value={status}
              onChange={(newStatus) => handleChangeStatus(id, newStatus)}
            >
              {STATUSES.map(({ id, name }) => (
                <Select.Option value={id}>{name}</Select.Option>
              ))}
            </Select>
            <Button
              size="small"
              type="ghost"
              onClick={() => handleShowModal("visibleSetPrice", id)}
            >
              Назначить цену
            </Button>
            <Button
              size="small"
              type="ghost"
              onClick={() => handleShowModal("visibleAuctionDelete", id)}
            >
              Удалить
            </Button>
          </Space>
        </Space>
      ),
    },
  ];
  const specialColumns = [
    columns[0],
    columns[1],
    columns[2],
    {
      title: "Макс. цена",
      width: 175,
      sorter: (a, b) => a.max_price - b.max_price,
      dataIndex: "max_price",
      align: "right",
      render: (text) => <div>{text} ₽</div>,
    },
    columns[3],
    columns[4],
    columns[5],
    columns[6],
  ];

  const winColumns = [
    specialColumns[0],
    specialColumns[1],
    specialColumns[2],
    specialColumns[3],
    {
      title: "Выигр. организация",
      sorter: (a, b) => {
        let wda = a.winning_organization;
        let wdb = b.winning_organization;
        if (!wda) wda = "";
        if (!wdb) wdb = "";
        return wda.localeCompare(wdb);
      },

      dataIndex: "winning_organization",
      width: 400,
      render: (text) =>
        text ? (
          <Text>{text}</Text>
        ) : (
          <Text type="secondary">{"Не указано"}</Text>
        ),
    },
    {
      title: "Цена по рез. торгов",
      width: 175,
      sorter: (a, b) => a.result_price - b.result_price,
      dataIndex: "result_price",
      align: "right",
      render: (text) => <div>{text} ₽</div>,
    },
    specialColumns[4],
    specialColumns[6],
    specialColumns[7],
  ];

  const getFilteredData = () => {
    // Фильтр по статусу
    let filteredData =
      filters.status == 7
        ? dataSource
        : dataSource.filter((auction) => auction.status == filters.status);

    return filteredData;
  };

  const getFilteredColumns = () => {
    const { status } = filters;
    return status == 1 || status == 2
      ? specialColumns
      : status == 3 || status == 4
      ? winColumns
      : columns;
    // return columns;
  };
  const filteredData = getFilteredData();
  const filteredColumns = getFilteredColumns();
  return (
    <Space size={"large"} direction="vertical">
      <Navbar onChange={handleChangeStatusFilters} status={filters.status} />
      <AuctionsFilters
        showDrawer={showDrawerToCreate}
        filters={filters}
        onChangeFilters={onChangeFilters}
        clearFilters={clearFilters}
      />
      <AlertWithTimer
        message="Торг был успешно удален"
        type="success"
        show={alertsStates.deleted}
      ></AlertWithTimer>
      <Table columns={filteredColumns} dataSource={filteredData} />
      {/* добавить потом аукцион ид */}
      <AuctionInfoDrawer
        auctionId={auctionId}
        isCreating={isCreating}
        onClose={closeDrawer}
        visible={isVisibleDrawer}
      />
      <ModalAuctionResult
        getAll={loadAllAuctions}
        auctionId={auctionId}
        visible={stateModals.visibleAuctionResult}
        handleOk={closeModals}
        handleCancel={closeModals}
      ></ModalAuctionResult>
      <ModalCostPrice
        auctionId={auctionId}
        visible={stateModals.visibleCostPrice}
        handleOk={closeModals}
        handleCancel={closeModals}
      ></ModalCostPrice>
      <ModalDelete
        visible={stateModals.visibleAuctionDelete}
        handleOk={deleteAuction}
        handleCancel={closeModals}
      ></ModalDelete>
      <ModalSetPrice
        auctionId={auctionId}
        visible={stateModals.visibleSetPrice}
        handleOk={closeModals}
        handleCancel={closeModals}
      ></ModalSetPrice>
    </Space>
  );
};

export default Auctions;
