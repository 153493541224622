import React from "react";
import { Button, DatePicker, Space, Input } from "antd";
import moment from "moment";
import "moment/locale/ru";

const { Search } = Input;

const AuctionsFilters = React.memo(
  ({
    showDrawer,
    onChangeFilters,
    filters = { dateFrom: "", dateTo: "", organization: "" },
    clearFilters,
  }) => {
    const onChangeDatePicker = (value, name) => {
      onChangeFilters({
        target: { name, value },
      });
    };
    return (
      <Space size="large">
        <Button onClick={showDrawer}>Добавить</Button>
        <Space size="small">
          Дата проведения аукциона с
          <DatePicker
            value={filters.dateFrom ? moment(filters.dateFrom) : ""}
            onChange={(dateMoment, dateString) =>
              onChangeDatePicker(dateString, "dateFrom")
            }
            placeholder="Выбрать дату"
          />
          по
          <DatePicker
            name="dateTo"
            value={filters.dateTo !== "" ? moment(filters.dateTo) : ""}
            onChange={(dateMoment, dateString) =>
              onChangeDatePicker(dateString, "dateTo")
            }
            placeholder="Выбрать дату"
          />
        </Space>
        <Input
          placeholder="Название организации"
          size="middle"
          name="organization"
          onChange={onChangeFilters}
          value={filters.organization}
          bordered
          // onSearch={search}
        />
        <Button type="dashed" onClick={clearFilters}>
          Очистить фильтры
        </Button>
      </Space>
    );
  }
);

export default AuctionsFilters;
