import React from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AuctionMainInfo from "../AuctionMainInfo";
const { Option } = Select;

const AuctionInfoDrawer = ({
  auctionId,
  visible,
  onClose,
  isCreating = false,
}) => {
  return (
    <>
      <Drawer
        title={isCreating ? "Добавить новый торг" : "Информация о торге"}
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
      >
        <AuctionMainInfo
          visible={visible}
          auctionId={auctionId}
          onClose={onClose}
          isCreating={isCreating}
        ></AuctionMainInfo>
      </Drawer>
    </>
  );
};

export default AuctionInfoDrawer;
