import React from "react";
import { Layout } from "antd";
import Navbar from "../Navbar/Navbar";
import { useHistory } from "react-router-dom";
const { Header, Content } = Layout;

const RouteWrapper = ({ children }) => {
  const history = useHistory();
  // const isShowNavbar = !history.location.pathname.includes("login");
  return (
    <Layout>
      {/* {isShowNavbar ? (
        <Header style={{ backgroundColor: "white" }}>
          <Navbar />
        </Header>
      ) : null} */}

      <Content
        style={{
          padding: "20px 70px",
          backgroundColor: "white",
          // justifyContent: "center",
        }}
      >
        {children}
      </Content>
    </Layout>
  );
};

export default RouteWrapper;
