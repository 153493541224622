import React from "react";
import { Alert } from "antd";
import { useEffect } from "react";
import { useState } from "react";

const AlertWithTimer = ({ message, type, closable = true, show = false }) => {
  const [isShow, setIsShow] = useState(show);

  const setAlertTimeout = () => {
    setIsShow(true);
    setTimeout(() => setIsShow(false), 3000);
  };
  useEffect(() => {
    if (show !== false) setAlertTimeout();
  }, show);

  return !isShow ? null : (
    <Alert message={message} type={type} closable={closable} />
  );
};

export default AlertWithTimer;
