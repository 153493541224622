import React from "react";
import Modal from "antd/lib/modal/Modal";
import AuctionResult from "../AuctionResult";

const ModalAuctionResult = ({ auctionId, visible, handleOk, handleCancel, getAll }) => {
  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={653}
      // title="Результаты аукциона"
      footer={null}
    >
      <AuctionResult getAll={getAll} auctionId={auctionId}></AuctionResult>
    </Modal>
  );
};

export default ModalAuctionResult;
