export const API_URL = "http://torgi.spbkomplekt.ru/api/";

export const STATUSES = [
  {
    id: 1,
    name: "Одобрена",
  },
  {
    id: 2,
    name: "Ждет одобрения",
  },
  {
    id: 3,
    name: "Не выигранные",
  },
  {
    id: 4,
    name: "Выигранная",
  },
  {
    id: 5,
    name: "Отклоненные",
  },
  // {
  //   id: 6,
  //   name: "В архив",
  // },
];

export const C = {
  auth: "auth",
  employee: "employee", // Сотрудники
  final_org: "final_org", // Сторонняя организация
  form: "form", // Форма торгов
  marketplace: "marketplace", // Торговая площадка
  our_org: "our_org", // Наша организаци
  cost_price: "cost_price", // Себестоимость
  auction: "auction", // Аукционы
};

export const T = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const BODY = {
  filterAuctions: {
    status: 7,
    page: 1,
    countOnPage: 100000,
    dateFrom: "",
    dateTo: "",
    organization: "",
  },
  names: {
    organization: "",
  },

  status: {
    status: 1,
  },
  auction: {
    name: "",
    city: "",
    form_id: null,
    description: "",
    supply_date: "",
    agree_date: "",
    execution_date: "",
    period: null,
    max_price: null,
    security_amount: null,
    fixed_price: null,
    period: "",
    out_org_id: null,
    href: "",
    designer_id: null,
    executer_id: null,
    marketplace_id: null,
    description2: "",
    status: null,
  },
  costPrice: {
    tax: null,
    materials: null,
    salary: null,
    fare: null,
    people_delivery: null,
    subcontractor: null,
    equipment: null,
    calc_date: "",
    calc_date2: "",
    calc_employee_id: null,
    calc_employee_id2: null,
  },
  result: {
    is_win: null,
    final_price: null,
    final_org_id: null,
    comment: "",
  },
  registration: {
    name: "",
    email: "",
    is_admin: "",
  },
  login: {
    password: "",
    email: "",
  },
  uniqCostPrice: {
    auction_id: null,
    name: "",
    price: 0,
  },
  form: {
    form: "",
  },
  our_org: {
    our_org: "",
  },
  final_org: {
    final_org: "",
  },
  employee: {
    employee: "",
  },
  marketplace: {
    marketplace: "",
  },
};
