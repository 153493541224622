import React from "react";
import { Form, Input, Button, Checkbox, Space } from "antd";
import { useHistory } from "react-router-dom";
import { Authorization } from "../api/methods";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Auth = () => {
  const history = useHistory();

  const onFinish = (values) => {
    const success = ({ data }) => {
      localStorage.setItem("tokenGetTime", new Date());
      localStorage.setItem("accessToken", data["access_token"]);
      localStorage.setItem("expiresIn", data["expires_in"]);
      history.push("/auctions");
    };

    localStorage.clear();
    Authorization.login(values, success);
  };

  const onFinishFailed = (errorInfo) => {};

  const initialValues = {
    // email: "cugfug576@gmail.com",
    // password: "6q5e8z1bxF",
    email: "",
    password: "",
  };

  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        display: "flex",
        paddingTop: 350,
      }}
    >
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        size="middle"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Email"
          initialValue={initialValues.email}
          name="email"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите логин!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          initialValue={initialValues.password}
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите пароль!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        {/* 
        <Form.Item {...tailLayout} name="isRememberMe" valuePropName="checked">
          <Checkbox>Запомнить меня</Checkbox>
        </Form.Item> */}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Auth;
