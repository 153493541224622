import { Button, Input, Modal, Row, Select, Space } from "antd";
import FormItem from "antd/lib/form/FormItem";
import Text from "antd/lib/typography/Text";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  commonCreateMethod,
  commonDeleteMethod,
  commonUpdateMethod,
} from "../../api/common";

const FormItemWithCRUD = ({
  methods = {
    create: () => {},
    getAll: () => {},
    update: () => {},
    delete: () => {},
  },
  onChange,
  placeholder = "",
  defaultValue = [],
  name = "",
  apiFieldName = "",
}) => {
  const [state, setState] = useState({
    selectedItem: defaultValue,
    isCreateMode: false,
    isUpdateMode: false,
    isItemSelected: false,
    newValue: "",
    options: [],
  });

  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [new_id, setNewId] = useState(null);

  const {
    selectedItem,
    isCreateMode,
    isItemSelected,
    isUpdateMode,
    newValue,
    options,
  } = state;

  const showDeleteModal = () => setIsVisibleDeleteModal(true);
  const hideDeleteModal = () => setIsVisibleDeleteModal(false);

  const stopEdditingMode = () =>
    setState({
      ...state,
      isUpdateMode: false,
      isCreateMode: false,
      newValue: "",
    });

  const getOptions = (open) => {
    const isOnDidMount = typeof open === "undefined";

    const success = ({ data }) => {
      const selected = defaultValue;
      // options.filter((o) => o.id == selectedItem).length == 0
      //   ? isOnDidMount
      //     ? defaultValue
      //     : []
      //   : selectedItem == null
      //   ? []
      //   : selectedItem;
      setState({
        ...state,
        options: data,
        selectedItem: selected,
        isItemSelected: selected != [],
      });
    };
    if (open || isOnDidMount) methods.getAll(success);
  };

  useEffect(() => {
    getOptions();
    // setState({ ...state, selectedItem: [defaultValue] });
  }, [defaultValue]);
  const getAll = (selectedItem = []) => {
    const successGetAll = ({ data }) => {
      setState({
        ...state,
        options: data,
        isUpdateMode: false,
        isCreateMode: false,
        newValue: "",
        selectedItem,
        // isItemSelected: selectedItem.length !== 0,
      });
    };
    methods.getAll(successGetAll);
  };

  const startUpdate = () =>
    setState({
      ...state,
      isUpdateMode: true,
      newValue: options.find((o) => o.id === selectedItem)[apiFieldName],
    });
  const startCreate = () => setState({ ...state, isCreateMode: true });

  const update = () => {
    const success = () => {
      stopEdditingMode();
      getAll(selectedItem);
    };

    commonUpdateMethod(
      methods.update,
      selectedItem,
      apiFieldName,
      newValue,
      success
    );
  };

  const create = () => {
    const success = ({ data }) => {
      stopEdditingMode();
      getAll(data.id);
    };
    commonCreateMethod(methods.create, apiFieldName, newValue, success);
  };

  const handleDelete = () => {
    const success = () => {
      hideDeleteModal();
      setNewId(null);
      getAll();
    };
    commonDeleteMethod(methods.delete, selectedItem, new_id, success);
  };

  const handleAcceptClick = () =>
    isCreateMode ? create() : isUpdateMode ? update() : null;

  const handleChange = (selectedItem) => {
    onChange({ target: { value: selectedItem, name } });
    setState({
      ...state,
      selectedItem,
      isItemSelected: selectedItem !== null,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSelectNewId = (value) => setNewId(value);

  const filteredOptions = options.filter((o) => o.id != selectedItem?.id);
  return (
    <>
      <Modal
        visible={isVisibleDeleteModal}
        onOk={handleDelete}
        onCancel={hideDeleteModal}
        title="При удалении подсказки вам нужно выбрать другое значение, которое встанет на замену."
        cancelText="Отмена"
        okText="Удалить и заменить"
        // width={844}
      >
        <FormItem>
          <Select
            onDropdownVisibleChange={getOptions}
            onChange={handleSelectNewId}
          >
            {filteredOptions.map((o) => (
              <Select.Option key={o.id} value={o.id}>
                {o[apiFieldName]}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Modal>
      <Space>
        {isUpdateMode || isCreateMode ? (
          <Input
            onChange={handleInputChange}
            name="newValue"
            value={newValue}
          />
        ) : (
          <Select
            placeholder={placeholder}
            value={selectedItem}
            defaultValue={defaultValue}
            onChange={handleChange}
            style={{ width: 300 }}
            onDropdownVisibleChange={getOptions}
          >
            {filteredOptions.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item[apiFieldName]}
              </Select.Option>
            ))}
          </Select>
        )}
        {isUpdateMode || isCreateMode ? (
          <>
            <Button
              type="primary"
              disabled={newValue === ""}
              onClick={handleAcceptClick}
            >
              Сохранить
            </Button>
            <Button type="ghost" onClick={stopEdditingMode}>
              Отмена
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={!isItemSelected}
              onClick={startUpdate}
              type="ghost"
            >
              Изменить
            </Button>
            <Button onClick={startCreate} type="ghost">
              Добавить
            </Button>
            <Button
              onClick={showDeleteModal}
              disabled={!isItemSelected}
              type="ghost"
            >
              Удалить
            </Button>
          </>
        )}
      </Space>
    </>
  );
};

export default FormItemWithCRUD;
