import React from "react";
import { Menu } from "antd";

export const STATUSES = [
  {
    id: 1,
    name: "Одобрены",
  },
  {
    id: 2,
    name: "Ждут одобрения",
  },
  {
    id: 3,
    name: "Не выигранные",
  },
  {
    id: 4,
    name: "Выигранные/подписанные",
  },
  {
    id: 5,
    name: "Отклоненные",
  },
  // {
  //   id: 6,
  //   name: "Архив",
  // },
  {
    id: 7,
    name: "Все",
  },
];

const Navbar = ({ onChange, status = 7 }) => {
  return (
    <Menu
      theme="light"
      mode="horizontal"
      defaultValue={7}
      selectedKeys={[String(status)]}
    >
      {STATUSES.map(({ id, name }) => (
        <Menu.Item
          onClick={onChange}
          // isSelected={true}
          key={id}
        >
          {name}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default Navbar;
