import React from "react";
import { Space, Typography } from "antd";
const { Text } = Typography;

const FormElement = ({ label = "", children }) => (
  <Space direction="vertical" style={{width:"100%"}} size="small">
    <Text>{label}</Text>
    {children}
  </Space>
);

export default FormElement;
