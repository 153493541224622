import React from "react";
import { Button, Input, Select, Space, Switch, Table, Typography } from "antd";
import { useParams } from "react-router-dom";
import { Option } from "antd/lib/mentions";
import FormElement from "../components/FormElement/FormElement";
import { useState } from "react";
import { useEffect } from "react";
import { Auction, Employee } from "../api/methods";
import FormItemWithCRUD from "../components/FormItemWithCRUD/FormItemWithCRUD";
import CustomDatePicker from "../components/CustomDatePicker/CustomDatePicker";

const { Text, Lin } = Typography;

const costPriceFields = [
  "tax",
  "materials",
  "salary",
  "fare",
  "people_delivery",
  "subcontractor",
  "equipment",
];

const AuctionCostPrice = ({ auctionId, hide }) => {
  const [data, setData] = useState([]);

  const [state, setState] = useState({
    tax: "0",
    materials: "0",
    salary: "0",
    fare: "0",
    people_delivery: "0",
    subcontractor: "0",
    equipment: "0",
    calc_date: "",
    calc_date2: "",
    calc_employee_id: "",
    calc_employee_id2: "",
    is_attached_cost_price: 0,
  });

  const getAuctionById = (id) => {
    const success = ({ data }) => {
      const pushDataToTable = (arr, key, name, price = "0", apiFieldName) =>{
        arr.push({
          key,
          name,
          price:price == null ?0:price,
          apiFieldName,
        })}

      const dataArr = [];
      pushDataToTable(dataArr, "1", "Налоги", data.tax, "tax");
      pushDataToTable(dataArr, "2", "Материалы", data.materials, "materials");
      pushDataToTable(dataArr, "3", "Фонд зар. платы", data.salary, "salary");
      pushDataToTable(dataArr, "4", "Транспортные расходы", data.fare, "fare");
      pushDataToTable(dataArr, "5", "Доставка и размещение людей",data.people_delivery,"people_delivery");
      pushDataToTable(dataArr, "6", "Привлечение субподрядчика",data.subcontractor,"subcontractor");
      pushDataToTable(dataArr, "7", "Аренда оборудования и техники", data.equipment,"equipment");


      data.tax = data.tax == null? 0: data.tax;
      data.materials = data.materials == null? 0: data.materials;
      data.salary = data.salary == null? 0: data.salary;
      data.fare = data.fare == null? 0: data.fare;
      data.people_delivery = data.people_delivery == null? 0: data.people_delivery;
      data.subcontractor = data.subcontractor == null? 0: data.subcontractor;
      data.equipment = data.equipment == null? 0: data.equipment;
      
      
      const result = dataArr.reduce((sum, { price }) => sum + price, 0);
      pushDataToTable(dataArr, "8", "Итого", result);
      setData(dataArr);
      // data = data.map(price=>price == null ?0:price)
      setState({
        ...data,
        calc_date: data.calc_date ? data.calc_date : new Date().toString(),
        calc_date2: data.calc_date2 ? data.calc_date2 : new Date().toString(),
      });
    };
    Auction.getById(id, success);
  };

  useEffect(() => {
    getAuctionById(auctionId);
  }, auctionId);

  const updateCostPrice = (newState) => {
    const success = (res) => console.log(res);
    Auction.updateCostPriceInfo(auctionId, newState, success);
  };

  const getCostPriceSum = (newState) =>
    costPriceFields.reduce((priceSum, field) => {
      if (isNaN(parseInt(newState[field]))) {
        console.log(priceSum);
        return priceSum;
      }
      {
        return parseInt(newState[field]) + parseInt(priceSum);
      }
    }, 0);

  const setPriceSum = (newState) => {
    const sum = getCostPriceSum(newState);
    const bufData = data.map((row) => {
      if (row.name === "Итого")
        // if (isNaN(sum)) {
        // row.price = "Введите корректные значения";
        // } else {
        row.price = sum;
      // }
      return row;
    });
    setData(bufData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const isCostPriceAndNaN = costPriceFields.includes(name) && isNaN(value);
    console.log(isCostPriceAndNaN);
    if (isCostPriceAndNaN) {
      return;
    }

    const newState = { ...state, [name]: value };
    setPriceSum(newState);
    setState(newState);
    updateCostPrice(newState);
  };

  const udpateIsAttachCostPrice = (isAttached) => {
    const success = (res) => console.log(res);
    isAttached
      ? Auction.chainCostPrice(auctionId, success)
      : Auction.unchainCostPrice(auctionId, success);

    setState({ ...state, is_attached_cost_price: isAttached });
  };

  const attach = () => udpateIsAttachCostPrice(true);
  const unattach = () => udpateIsAttachCostPrice(false);

  const columns = [
    {
      dataIndex: "name",
    },
    {
      dataIndex: "price",
      render: (text, rowInfo) => {
        if (rowInfo.name === "Итого") return <div>{text} ₽</div>;

        return (
          <Input
            onChange={handleChange}
            name={rowInfo.apiFieldName}
            defaultValue={text}
            addonAfter={"₽"}
          />
        );
      },
    },
    { dataIndex: "apiFieldName", render: () => null },
  ];

  return (
    <Space size="middle" direction="vertical">
      <Space size="small">
        {state.is_attached_cost_price == 1 ? (
          <Button size="small" type="dashed" onClick={unattach}>
            Удалить
          </Button>
        ) : (
          <Button size="small" type="primary" onClick={attach}>
            Прикрепить
          </Button>
        )}
      </Space>
      {state.is_attached_cost_price == 1 ? (
        <>
          <Table
            bordered={false}
            pagination={false}
            size="small"
            showHeader={false}
            dataSource={data}
            columns={columns}
          />
          <Space size="middle">
            <FormElement  label="Дата проведения обсчета">
              <CustomDatePicker
                required
                onChange={handleChange}
                name={"calc_date"}
                value={state["calc_date"]}
              />
            </FormElement>
            <FormElement  label="Обсчет произвел">
              <FormItemWithCRUD

                name="calc_employee_id"
                methods={Employee}
                apiFieldName="employee"
                onChange={handleChange}
                defaultValue={state.calc_employee_id}
              />
            </FormElement>
          </Space>
          <Space size="middle">
            <FormElement label="Дата проведения расчета">
              <CustomDatePicker
                onChange={handleChange}
                name={"calc_date2"}
                value={state["calc_date2"]}
              />
            </FormElement>
            <FormElement label="Расчет произвел">
              <FormItemWithCRUD
                name="calc_employee_id2"
                methods={Employee}
                apiFieldName="employee"
                onChange={handleChange}
                defaultValue={state.calc_employee_id2}
              />
            </FormElement>
          </Space>
        </>
      ) : null}
    </Space>
  );
};

export default AuctionCostPrice;
