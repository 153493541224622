import React from "react";
import { Alert, Button, Input, Select, Space, Switch, Typography } from "antd";
import { useParams } from "react-router-dom";
import { Option } from "antd/lib/mentions";
import FormElement from "../components/FormElement/FormElement";
import { useState } from "react";
import { useEffect } from "react";
import { Auction, Employee, FinalOrganization } from "../api/methods";
import FormItemWithCRUD from "../components/FormItemWithCRUD/FormItemWithCRUD";
import { STATUSES } from "../api/constants";

const { Text, Lin } = Typography;

const AuctionResult = ({ auctionId, getAll }) => {
  const [state, setState] = useState({
    is_attached_result: "",
    is_win: "",
    final_price: "",
    final_org_id: "",
    comment: "",
  });

  const setIsAttachedResult = (isAttached) =>
    setState({ ...state, is_attached_result: isAttached });

  const updateIsAttachedResult = (isAttachedResult) => {
    const success = (res) => {
      getAll()
      // alert("Успешно обновлено");
    };
    setIsAttachedResult(isAttachedResult);
    if (isAttachedResult) {
      Auction.chainResult(auctionId, success);
      Auction.update(auctionId, { status: state.is_win == 1 ? 4 : 3 }, success);
    } else {
      Auction.unchainResult(auctionId, success);
      Auction.update(auctionId, { status: 1 }, success);
    }
  };

  const loadAuctionInfo = () => {
    const success = ({ data }) => {
      setState(data);
    };
    Auction.getById(auctionId, success);
  };

  useEffect(() => {
    loadAuctionInfo();
  }, [auctionId]);

  const updateResultInfo = (newState) => {
    const success = (res) => {
      console.log(res);
    };
    Auction.updateResultInfo(
      auctionId,
      { ...newState, is_win: newState.is_win ? 1 : 0 },
      success
    );
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    const newState = { ...state, [name]: value };

    setState(newState);
    updateResultInfo(newState);
  };

  const attachResult = () => updateIsAttachedResult(true);
  const unattachResult = () => updateIsAttachedResult(false);

  const onChangeIsWin = (checked) => {
    const newState = { ...state, is_win: checked };
    setState(newState);
    Auction.update(auctionId, { status: checked ? 4 : 3 });
    updateResultInfo(newState);
  };

  return (
    <Space size="middle" direction="vertical">
      <Space size="small">
        <Text strong>Результаты торгов</Text>
        {state.is_attached_result == 1 ? (
          <Button size="small" type="dashed" onClick={unattachResult}>
            Удалить
          </Button>
        ) : (
          <Button size="small" type="primary" onClick={attachResult}>
            Прикрепить
          </Button>
        )}
      </Space>
      {state.is_attached_result == 1 ? (
        <>
          <Space size="middle" align="start">
            <FormElement label="Результат">
              <Switch
                checked={state.is_win}
                onChange={onChangeIsWin}
                checkedChildren="Выиграли"
                unCheckedChildren="Проиграли"
              ></Switch>
            </FormElement>
            <FormElement label="Цена">
              <Input
                name={"final_price"}
                value={state.final_price}
                onChange={handleChange}
                addonAfter="₽"
                placeholder=""
              />
            </FormElement>
          </Space>
          <FormElement label="Комментарий">
            <Input
              name={"comment"}
              value={state.comment}
              onChange={handleChange}
              placeholder=""
            />
          </FormElement>
          {state.is_win == 1 ? null : (
            <FormElement label="Наименование выигравшей организации">
              <FormItemWithCRUD
                methods={FinalOrganization}
                defaultValue={state.final_org_id}
                apiFieldName={"final_org"}
                name={"final_org_id"}
                onChange={handleChange}
              ></FormItemWithCRUD>
            </FormElement>
          )}
        </>
      ) : null}
    </Space>
  );
};

export default AuctionResult;
