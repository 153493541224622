import axios from "axios";
import { API_URL, C, T, BODY } from "./constants";

const logError = (err) => {
  console.log(err);
};

const commonRequest = (
  requestType,
  methodClass,
  method = "",
  success = () => console.error("Функция success не задана"),
  data = {}
) => {
  const token = "Bearer " + localStorage.getItem("accessToken");
  method = method === "" ? method : "/" + method;
  const response = (token) =>
    axios({
      method: requestType,
      baseURL: API_URL,
      url: methodClass + method,
      headers: {
        Authorization: token,
      },
      data,
    });
  response(token)
    .then(success)
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) document.location.replace("/");
      // console.log(err.response);
      // if (err.response)
      //   if (err.response.status == "401") {
      //     console.log(err.response);
      //     Authorization.refresh((res) => {
      //       console.log(res);
      //       // response(token).then(success);
      //     });
      //   }
    });
};

export const Authorization = {
  registration: (body = BODY.registration, success) =>
    commonRequest(T.POST, C.auth, "registration", success, body),
  login: (body = BODY.login, success) =>
    commonRequest(T.POST, C.auth, "login", success, body),
  me: (success) => commonRequest(T.POST, C.auth, "me", success),
  refresh: (success) => commonRequest(T.POST, C.auth, "refresh", success),
  logout: (success) => commonRequest(T.POST, C.auth, "logout", success),
};

export const Employee = {
  create: (body = BODY.employee, success) =>
    commonRequest(T.POST, C.employee, "", success, body),
  getAll: (success) => commonRequest(T.GET, C.employee, "", success),
  update: (id, body = BODY.employee, success) =>
    commonRequest(T.PUT, C.employee, id, success, body),
  delete: (id, new_id, success) =>
    commonRequest(T.DELETE, C.employee, id, success, { new_id }),
};

export const FinalOrganization = {
  create: (body = BODY.final_org, success) =>
    commonRequest(T.POST, C.final_org, "", success, body),
  getAll: (success) => commonRequest(T.GET, C.final_org, "", success),
  update: (id, body = BODY.final_org, success) =>
    commonRequest(T.PUT, C.final_org, id, success, body),
  delete: (id, new_id, success) =>
    commonRequest(T.DELETE, C.final_org, id, success, { new_id }),
};

export const OurOrganization = {
  create: (body = BODY.our_org, success) =>
    commonRequest(T.POST, C.our_org, "", success, body),
  getAll: (success) => commonRequest(T.GET, C.our_org, "", success),
  update: (id, body = BODY.our_org, success) =>
    commonRequest(T.PUT, C.our_org, id, success, body),
  delete: (id, new_id, success) =>
    commonRequest(T.DELETE, C.our_org, id, success, { new_id }),
};

export const AuctionForm = {
  create: (body = BODY.form, success) =>
    commonRequest(T.POST, C.form, "", success, body),
  getAll: (success) => commonRequest(T.GET, C.form, "", success),
  update: (id, body = BODY.form, success) =>
    commonRequest(T.PUT, C.form, id, success, body),
  delete: (id, new_id, success) =>
    commonRequest(T.DELETE, C.form, id, success, { new_id }),
};

export const Marketplace = {
  create: (body = BODY.marketplace, success) =>
    commonRequest(T.POST, C.marketplace, "", success, body),
  getAll: (success) => commonRequest(T.GET, C.marketplace, "", success),
  update: (id, body = BODY.marketplace, success) =>
    commonRequest(T.PUT, C.marketplace, id, success, body),
  delete: (id, new_id, success) =>
    commonRequest(T.DELETE, C.marketplace, id, success, { new_id }),
};

export const UniqCostPrice = {
  create: (body = BODY.uniqCostPrice, success) =>
    commonRequest(T.POST, C.cost_price, "", success, body),
  update: (id, body = BODY.uniqCostPrice, success) =>
    commonRequest(T.PUT, C.cost_price, id, success, body),
  getAll: (success) => commonRequest(T.GET, C.cost_price, "", success),
  delete: (id, new_id, success) =>
    commonRequest(T.DELETE, C.cost_price, id, success, { new_id }),
};

export const Auction = {
  create: (body = BODY.auction, success) =>
    commonRequest(T.POST, C.auction, "", success, body),
  update: (id, body = BODY.auction, success) =>
    commonRequest(T.PUT, C.auction, id, success, body),
  getAll: (body = BODY.filterAuctions, success) => {
    if (body.status == 7) body.status = "";
    commonRequest(T.POST, C.auction, "all", success, body);
  },

  getNamesBySubstring: (body = BODY.names, success) =>
    commonRequest(T.POST, C.auction, "names", success, body),
  getById: (id, success) => commonRequest(T.GET, C.auction, id, success),
  delete: (id, success) => commonRequest(T.DELETE, C.auction, id, success),

  changeStatus: (id, body = BODY.status, success) =>
    commonRequest(T.POST, C.auction, `status/${id}`, success, body),

  chainCostPrice: (id, success) =>
    commonRequest(T.GET, C.auction, `cost_price/${id}`, success),
  unchainCostPrice: (id, success) =>
    commonRequest(T.DELETE, C.auction, `cost_price/${id}`, success),
  updateCostPriceInfo: (id, body = BODY.costPrice, success) =>
    commonRequest(T.PUT, C.auction, `cost_price/${id}`, success, body),

  chainResult: (id, success) =>
    commonRequest(T.GET, C.auction, `result/${id}`, success),
  unchainResult: (id, success) =>
    commonRequest(T.DELETE, C.auction, `result/${id}`, success),
  updateResultInfo: (id, body = BODY.result, success) =>
    commonRequest(T.PUT, C.auction, `result/${id}`, success, body),
};
