import React from "react";
import Modal from "antd/lib/modal/Modal";
import AuctionCostPrice from "../AuctionCostPrice";

const ModalCostPrice = ({ auctionId, visible, handleOk, handleCancel }) => {
  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      title="Себестоимость"
      footer={null}
      cancelText="Отмена"
      okText="Сохранить"
      width={844}
    >
      <AuctionCostPrice auctionId={auctionId}></AuctionCostPrice>
    </Modal>
  );
};

export default ModalCostPrice;
