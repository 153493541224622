import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import AuctionResult from "../AuctionResult";
import FormElement from "../../components/FormElement/FormElement";
import { Input } from "antd";
import { Auction } from "../../api/methods";
import { useEffect } from "react";

const ModalSetPrice = ({ auctionId, visible, handleOk, handleCancel }) => {
  const [fixed_price, set_fixed_price] = useState("");

  const loadFixedPrice = (auctionId) => {
    const success = ({ data }) => set_fixed_price(data.fixed_price);
    Auction.getById(auctionId, success);
  };
  useEffect(() => {
    loadFixedPrice(auctionId);
  }, [auctionId]);

  const updateFixedPrice = () => {
    Auction.update(auctionId, { fixed_price }, handleOk);
  };
  const onChange = (e) => {
    const { value } = e.target;
    set_fixed_price(value);
  };
  const customHandleOk = () => {
    updateFixedPrice();
  };

  return (
    <Modal
      visible={visible}
      onOk={customHandleOk}
      onCancel={handleCancel}
      title="Проставить назначенную цену"
      cancelText="Отмена"
      okText="Сохранить"
    >
      <FormElement label="Назначенная цена">
        <Input
          name="fixed_price"
          onChange={onChange}
          value={fixed_price}
          addonAfter="₽"
        />
      </FormElement>
    </Modal>
  );
};

export default ModalSetPrice;
