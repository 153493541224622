import React from "react";
import { Select, Space, Typography } from "antd";
import { useParams } from "react-router-dom";
import { Option } from "antd/lib/mentions";

const { Text, Link } = Typography;

const AuctionTopBar = () => {
  const { id } = useParams();
  return (
    <Space size="large">
      <Space size="small">
        <Select defaultValue="1">
          <Option value="1">Одобрено</Option>
          <Option value="2">Статус 2</Option>
          <Option value="3">Статус 3</Option>
        </Select>
      </Space>
      <Link>Отчет</Link>
      <Text type="secondary">Удалить</Text>
    </Space>
  );
};

export default AuctionTopBar;
