import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "moment/locale/ru";

const CustomDatePicker = ({ value = "", name, onChange, required = false }) => {
  const onChangeDatePicker = (dateMoment, dateString) => {
    onChange({
      target: { name, value: dateString },
    });
  };

  const isInvalidValue =
    value === "" ||
    value === "0000-00-00" ||
    value == null ||
    typeof value === "undefined";
  value = isInvalidValue ? "" : moment(value);

  return (
    <DatePicker
      allowClear={false}
      name={name}
      value={value}
      onChange={onChangeDatePicker}
      placeholder="Выбрать дату"
    />
  );
};

export default CustomDatePicker;
